// Create a button that will display all the colours in the visualization tree and what they mean
// Colour data can be found in './depthColourSchema.json'
// Use ColorLensIcon from MUI Icons to display the button to click
// Display the colour legend in a box in the bottom right corner of the screen
// When clicked away from the box, the box will disappear
import { ColorLens } from "@mui/icons-material";
import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import "../depth.scss";
import depthColourScheme from "../depthColourScheme.json";

export default function ColourLegend() {
	const [showLegend, setShowLegend] = useState(false);

	// Convert the colour scheme object into an array of objects with keys 'colour' and 'hex'
	const colourScheme = Object.keys(depthColourScheme).map((colour) => ({
		colour,
		hex: depthColourScheme[colour],
	}));

	return (
		<div>
			<Tooltip arrow describeChild placement="top" title="Colour Legend">
				<IconButton alt="Colour Legend" onClick={() => setShowLegend(!showLegend)}>
					<ColorLens />
				</IconButton>
			</Tooltip>

			{showLegend && (
				<Paper
					data-id="colourLegend"
					style={{
						position: "absolute",
						bottom: "4vh",
						right: "0",
						backgroundColor: "#15385b",
						border: "1px solid black",
						borderRadius: "5px",
						padding: "5px",
						margin: "5px",
						minWidth: "12vw",
					}}
				>
					{colourScheme.map((colour) => (
						<Box key={colour.colour} sx={{ display: "flex", alignItems: "center" }}>
							<Box
								sx={{
									width: "20px",
									height: "20px",
									backgroundColor: colour.hex,
									border: "1px solid black",
									borderRadius: "5px",
									marginRight: "5px",
								}}
							/>
							<Typography color="white" variant="body2">
								{colour.colour}
							</Typography>
						</Box>
					))}
				</Paper>
			)}
		</div>
	);
}
