import { Search as SearchIcon } from "@mui/icons-material";
import { Autocomplete, IconButton, InputAdornment, TextField } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { doiNameChanger } from "../../helpers/doi";
import loginAnonymous from "../../mongoDB-connect";
import sampleAutofill from "./sample-autofill.json";
import "./search-bar.scss";

export default function SearchBar({ maxWidth }) {
	const [autofillOptions, setAutofillOptions] = useState([]);

	const alreadySearched = useRef([]);
	const autofillOptionsNonState = useRef([]);
	const autofillDOIs = useRef([]);

	/** Sets the width for the search bar */
	const setWidth = `${maxWidth || 85}%`;

	useEffect(() => {
		for (const i in sampleAutofill) {
			if (!autofillDOIs.current.includes(sampleAutofill[i].doi)) {
				autofillDOIs.current.push(sampleAutofill[i].doi);
				autofillOptionsNonState.current.push(sampleAutofill[i]);
			}
		}

		setAutofillOptions([...autofillOptionsNonState.current]);
	}, []);

	const handleKeyPress = async (event) => {
		if (!["shift", "enter"].includes(event.key.toLowerCase()) && event?.target?.defaultValue) {
			const query = event.target.defaultValue.trim();

			if (!alreadySearched.current.includes(query.trim().toLowerCase())) {
				alreadySearched.current.push(query.trim().toLowerCase());

				const regexSearch = new RegExp(`${query.replace(/[^A-Z0-9]+/gi, ".")}`, "i");

				const mongoQuery = {
					$or: [{ doi: regexSearch }, { title: regexSearch }, { authors: regexSearch }],
				};

				const projection = {
					projection: {
						doi: 1,
						title: 1,
						authors: 1,
						_id: 0,
					},
				};

				projection.limit = 5;

				await loginAnonymous()
					.then((db) => db.collection(process.env.REACT_APP_MONGODB_COLLECTION).find(mongoQuery, projection))
					.then((docs) => {
						if (docs) {
							for (const i in docs) {
								if (!autofillDOIs.current.includes(docs[i]?.doi)) {
									const optionFilter = [];
									if (docs[i].doi) {
										optionFilter.push(docs[i].doi);
									}
									if (docs[i].title) {
										optionFilter.push(docs[i].title);
									}
									if (docs[i].authors) {
										optionFilter.push(docs[i].authors.join(", "));
									}

									// eslint-disable-next-line no-param-reassign
									docs[i].label = optionFilter.join(" | ");

									autofillDOIs.current.push(docs[i].doi);
									autofillOptionsNonState.current.push(docs[i]);
								}
							}
						}
					})
					.catch((err) => {
						console.error(err);
					});

				setAutofillOptions([...autofillOptionsNonState.current]);
			}
		}
	};

	const redirectToDOI = (doiInput) => {
		let doiChangeTo;

		if (doiInput?.doi?.length > 0) {
			doiChangeTo = doiInput.doi;
		} else if (typeof doiInput === "string" && doiInput.length > 0) {
			doiChangeTo = doiInput.trim();
		} else {
			doiChangeTo = document.getElementById("searchBar").value.trim();
		}

		if (!isEmpty(doiChangeTo)) {
			let redirectTo = `/viz?doi=${doiNameChanger(doiChangeTo, "db")}`;
			if (!doiChangeTo.includes("/")) {
				redirectTo = `/search?query=${doiNameChanger(doiChangeTo, "db")}`;
			}

			window.location.href = `${window.location.origin}${redirectTo}`;
		}
	};

	return (
		<div className="searchBar" id="searchBarContainer" style={{ width: setWidth }}>
			<Autocomplete
				aria-label="Search"
				freeSolo
				getOptionLabel={(option) => option.label}
				id="searchBar"
				onChange={(_event, newValue) => {
					redirectToDOI(newValue);
				}}
				onKeyUp={(event, _newInputValue) => {
					handleKeyPress(event);
				}}
				options={autofillOptions}
				renderInput={(params) => (
					<TextField
						{...params}
						aria-label="Search for publication"
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<>
									<InputAdornment position="end">
										<IconButton
											aria-label="search"
											onClick={() => {
												redirectToDOI(params.InputProps.value);
											}}
											title="Search for publication"
										>
											<SearchIcon />
										</IconButton>
									</InputAdornment>

									{params.InputProps.endAdornment}
								</>
							),
						}}
						margin="normal"
						placeholder="Search for publication"
						variant="outlined"
					/>
				)}
				renderOption={(props, option, { inputValue }) => {
					const optionName = `${option.doi} (${option.title})`;
					/** All matches */
					const matches = match(optionName, inputValue, {
						findAllOccurrences: true,
						insideWords: true,
					});
					/** Word parts that match */
					const parts = parse(optionName, matches);

					return (
						<li {...props}>
							<div>
								{parts.map((part, _index) => (
									<span
										// eslint-disable-next-line react/no-array-index-key
										key={`${part.text}-search-${Math.random() * 100000}`}
										style={{
											fontWeight: part.highlight ? 700 : 400,
										}}
									>
										{part.text}
									</span>
								))}
							</div>
						</li>
					);
				}}
				sx={{
					margin: "1% auto",
					".MuiInputBase-root": {
						padding: "0.5% 1%",
					},
				}}
				title="Search by DOI, title, or author"
			/>
		</div>
	);
}
