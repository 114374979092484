// Generate a dynamic structured data system
/**
 * Generate a dynamic structured data system based on the current page
 * @param {Object} data The structured data object
 * @param {String} data.url The current page url
 * @param {String} data.name The current page name
 * @param {String} data.description The current page description
 * @param {String} data.dataPublished The current page published date
 * @param {Boolean} returnData Whether you want the structured data returned [true] or added to document's head [false, default]
 * @returns {Object} The structured data object
 */
export function dynamicStructuredData(data, returnData = false) {
	/** Structured data's URL */
	const url = data?.url || window?.location?.href || "https://impactdepth.com/";

	/** Structured data */
	const structuredData = [
		{
			"@context": "http://schema.org",
			"@type": "WebPage",
			description:
				data?.description ||
				"Impact Depth is a web tool that is meant to visualize citation flow and citation depth that a publication makes towards the scientific community.",
			image: "https://impactdepth.com/icon/resoc.png",
			name: "Impact Depth - Visualize citation flow and depth",
			url: "https://impactdepth.com/",
		},
		{
			"@context": "http://schema.org",
			"@type": "WebSite",
			datePublished: data?.datePublished || "2021-05-08",
			description:
				data?.description ||
				"Impact Depth is a web tool that is meant to visualize citation flow and citation depth that a publication makes towards the scientific community.",
			name: "Impact Depth",
			url,
		},
	];

	if (returnData) {
		return structuredData;
	}

	/** Create new script element which structured data will be added to */
	const el = document.createElement("script");
	el.type = "application/ld+json";
	el.text = JSON.stringify(structuredData);

	// Add the element to the document's head
	document.querySelector("head").appendChild(el);

	return null;
}

/**
 * Generate dynamic meta tags
 * @param {Object} data New meta tag data
 * @param {String} data.title The new title
 * @param {String} data.description The new description
 * @param {String} data.url The new url
 * @param {String} data.publishedTime The new published time (year-month-day)
 * @example <caption>Use this function to dynamically update meta tags</caption>
 * dynamicMetaTags({title: "New title", description: "New description", url: "https://impactdepth.com/", publishedTime: "2020-05-08"});
 * // returns null (does not return anything but rather update existing meta tags for the page)
 * @export
 */
export function dynamicMetaData(data) {
	/** New title */
	const contentTitle = data?.title || "Impact Depth - Visualize your publication's citation path";
	/** New description */
	const contentDescription =
		data?.description ||
		"Impact Depth is a web tool that is meant to visualize citation flow and citation depth that a publication makes towards the scientific community.";
	/** New published time (year-month-day) */
	const contentPublishedTime = data?.publishedTime || "2021-05-08";
	/** New url */
	const contentURL = data?.url || document?.location?.href || "https://impactdepth.com/";

	/** Updated meta tags */
	const customData = [
		// Basic meta data
		{
			content: contentTitle,
			name: "title",
		},
		{
			content: contentDescription,
			name: "description",
		},
		{
			href: contentURL,
			rel: "canonical",
		},
		// Open Graph meta data
		{
			content: contentURL,
			property: "og:url",
		},
		{
			content: contentTitle,
			property: "og:title",
		},
		{
			content: contentDescription,
			property: "og:description",
		},
		{
			content: contentDescription,
			property: "og:image:alt",
		},
		{
			content: contentURL,
			property: "og:site_name",
		},
		{
			content: `${contentPublishedTime}T00:00:00+00:00`,
			property: "article:published_time",
		},
		{
			content: `${contentPublishedTime}T00:00:00Z`,
			property: "og:publish_date",
		},
		{
			content: contentTitle,
			itemprop: "name",
		},
		{
			content: contentURL,
			itemprop: "url",
		},
		{
			content: contentDescription,
			itemprop: "description",
		},
		// Twitter meta data
		{
			content: contentURL,
			property: "twitter:url",
		},
		{
			content: contentTitle,
			property: "twitter:title",
		},
		{
			content: contentDescription,
			property: "twitter:description",
		},
		{
			content: contentTitle,
			name: "twitter:title",
		},
		{
			content: contentURL,
			name: "twitter:url",
		},
		{
			content: contentDescription,
			name: "twitter:description",
		},
	];

	// Go through each dynamic meta tag and either update or add it to the document's head
	customData.forEach((meta) => {
		/** Tag type */
		const type = meta.name
			? "name"
			: meta.property
				? "property"
				: meta.rel
					? "rel"
					: meta.itemprop
						? "itemprop"
						: undefined;

		// If looking to update rel canonical
		if (meta.rel === "canonical") {
			/** Current canonical tag */
			const currentCanonical = document.querySelector("link[rel='canonical']");
			// If there is no canonical tag, create one
			if (!currentCanonical) {
				const el = document.createElement("link");
				el.rel = "canonical";
				el.href = meta.href;
				document.querySelector("head").appendChild(el);
			} else {
				// If there is a canonical tag, update it
				currentCanonical.href = meta.href;
			}
		} else if (document.querySelector(`meta[${type}="${meta[type]}"]`)) {
			document.querySelector(`meta[${type}="${meta[type]}"]`).setAttribute("content", meta.content);
		} else {
			/** Meta element */
			const el = document.createElement("meta");

			el.setAttribute(type, meta[type]);
			el.setAttribute("content", meta.content);

			// Add the element to the document's head
			document.querySelector("head").appendChild(el);
		}
	});
}
