import React, { Suspense, lazy } from "react";
import { colourTree, visualizeDepth } from "../../visualization/depthVisualization/depth-functions";
import "./example.scss";
import sampleData from "./sampleData.json";
import samplePublicationData from "./samplePublicationData.json";

const Card = lazy(() => import("@mui/material/Card"));
const CardContent = lazy(() => import("@mui/material/CardContent"));
const Grid = lazy(() => import("@mui/material/Grid"));

export default class Example extends React.Component {
	constructor() {
		super();
		/** The SVG DOM ID */
		this.svgID = "depthTree";

		/** The width of the SVG column */
		this.svgColWidth = 12;

		this.state = {
			svgWidth: window.innerWidth,
			svgHeight: "70vh",
		};
	}

	async componentDidMount() {
		const { svgWidth, svgHeight } = this.state;

		if (window.innerWidth > 600) {
			this.setState({
				svgWidth: ((window.innerWidth * 0.9) / 12) * this.svgColWidth * 0.9,
			});
		} else {
			this.setState({
				svgWidth: window.innerWidth * 0.9 * 0.9,
			});
		}

		const customSize = {
			width: parseFloat(svgWidth),
			height: (parseFloat(svgHeight) / 100) * window.innerHeight,
		};

		setTimeout(async () => {
			await visualizeDepth(sampleData.tree, sampleData.doi, sampleData.depth, customSize, { enableZoom: false });
			await colourTree(samplePublicationData, sampleData.doi);
			await this.correctTree();
			document.title = `Impact Depth - Visualize your publication's citation path`;
		}, 500);
	}

	async correctTree() {
		const svgObject = document.getElementById(this.svgID);
		if (svgObject) {
			const svgTree = svgObject.firstElementChild;

			const svgObjectHeight = svgObject.getBoundingClientRect().height;
			const svgObjectWidth = svgObject.getBoundingClientRect().width;

			const svgTreeHeight = svgTree.getBoundingClientRect().height;
			const svgTreeWidth = svgTree.getBoundingClientRect().width;

			const widthMultiplier = 6;

			const adjustments = {};

			adjustments.y = 0;
			adjustments.x =
				svgObjectWidth > svgTreeWidth
					? (svgTreeWidth - svgObjectWidth) * widthMultiplier
					: (svgObjectWidth - svgTreeWidth) * widthMultiplier;
			adjustments.scale = 1;
			if (svgObjectHeight > svgTreeHeight || svgObjectWidth > svgTreeWidth) {
				adjustments.scale =
					svgTreeHeight / svgObjectHeight > svgTreeWidth / svgObjectWidth
						? svgTreeWidth / svgObjectWidth
						: svgTreeHeight / svgObjectHeight;
			}

			svgTree.setAttribute(
				"transform",
				`translate(${adjustments.x}, ${adjustments.y}) scale(${adjustments.scale})`,
			);
		}
	}

	render() {
		const { svgWidth, svgHeight } = this.state;

		return (
			<Suspense fallback={null}>
				<Card className="example-Card">
					<CardContent key="example-CardContent">
						<Grid alignItems="center" container justifyContent="center" spacing={1}>
							<Grid item xs={9}>
								<p className="example-Text" role="article">
									Use Impact Depth&apos;s search bar to find your publication of interest and see how
									many papers have cited your publication and your publication of interest, including
									what research fields those citations are from.
									<br />
									<br />
									The following example is from{" "}
									<a className="example-HiddenLink" href={`https://doi.org/${sampleData.doi}`}>
										{sampleData.doi}: {sampleData.title}
									</a>
									. See an interactive visualization at{" "}
									<a className="example-Link" href={sampleData.url}>
										{sampleData.url}
									</a>
									.
								</p>
							</Grid>
							<Grid item xs={12}>
								<div id="svgDOMContainer">
									<svg height={svgHeight} id={this.svgID} width={svgWidth}>
										<g id="svgContainer" transform="translate(0, 0)">
											<g className="links" />
											<g className="nodes" />
										</g>
									</svg>
								</div>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Suspense>
		);
	}
}
