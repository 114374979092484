import { Tooltip } from "@mui/material";
import React, { Suspense, lazy } from "react";
import { logAnalyticsEvent } from "../../../firebase";
import InfoBar from "../../components/info-bar/InfoBar";
import Depth from "../../components/visualization/depthVisualization/Depth";
import { colourTree } from "../../components/visualization/depthVisualization/depth-functions";
import { createCitationTree, doiNameChanger, totalCitationsInTree } from "../../helpers/doi";
import { dynamicMetaData, dynamicStructuredData } from "../../helpers/helper";
import loginAnonymous from "../../mongoDB-connect";
import "./visualization.scss";

const CitationInformation = lazy(
	() => import("../../components/visualization/citationInformation/CitationInformation"),
);
const Publications = lazy(() => import("../../components/visualization/publications/Publications"));
const LinearProgress = lazy(() => import("@mui/material/LinearProgress"));

/** Visualizes DOI data */
export default class Visualization extends React.Component {
	constructor() {
		super();

		/** DOI ID */
		this.doi = undefined;
		/** DOM ID for the depth visualization */
		this.depthID = "depthVisualization";
		/** DOM ID for the toolbar */
		this.toolbarID = "depthToolbar";
		/** Citation path data */
		this.citationData = {};
		/** All publication's information */
		this.publicationData = {};
		/** Already searched for DOIs from MongoDB */
		this.alreadyUsedDOI = [];
		/** Whether structured data has been created [true] or not [false, default] */
		this.createdStructuredData = false;

		// Content
		this.infoBarJSX = null;
		this.depthVisualizationJSX = null;
		this.citationInfoJSX = null;
		this.publicationsJSX = null;
		this.errorJSX = null;

		this.state = {
			jsxDisplay: null,
			loadingProgress: 0,
		};
	}

	async componentDidMount() {
		const { doi } = this.props;

		this.doi = doi;

		if (doi) {
			logAnalyticsEvent("visualization", {
				doi,
			});
		}

		await this.retrievePublicationData([doi], 0, true);

		this.updateProgressBar(0, true);
	}

	async getPublicationData(doiList, depth = 0, init = false) {
		const { doi: doiProps } = this.props;

		/** List of regex versions of the DOI list */
		const regexDOI = [];

		for (const i in doiList) {
			if (!this.alreadyUsedDOI.includes(doiList[i]) && !this.publicationData[doiList[i]]) {
				regexDOI.push(new RegExp(`^${doiList[i].replace(/[^A-Z0-9]+/gi, ".")}$`, "i"));

				this.alreadyUsedDOI.push(doiList[i]);
			}
		}

		/** MongoDB query */
		const mongoQuery = {
			doi: { $in: regexDOI },
		};
		/** Indicate to MongoDB what to return [1] and not return [0] */
		const projection = {
			projection: {
				doi: 1,
				title: 1,
				abstract: 1,
				fieldsOfStudy: 1,
				citations: 1,
				depth: 1,
				year: 1,
				_id: 0,
			},
		};

		if (regexDOI.length > 0) {
			/** Next DOIs to search */
			let nextDOIList = [];

			await loginAnonymous()
				.then((db) => db.collection(process.env.REACT_APP_MONGODB_COLLECTION).find(mongoQuery, projection))
				.then(async (docs) => {
					this.updateProgressBar(depth);

					if (docs?.length > 0) {
						for (const i in docs) {
							if (docs[i]?.doi && !this.publicationData[docs[i]?.doi]) {
								this.publicationData[docs[i]?.doi] = docs[i];

								if (docs[i]?.citations?.length > 0) {
									nextDOIList = [...nextDOIList, ...docs[i].citations];
								}
							}
						}

						if (this.publicationData[this.doi] && !this.createdStructuredData) {
							this.createdStructuredData = true;

							/** New dynamic data for meta tags and structured data */
							const dynamicData = {
								title: `Visualization for "${this.publicationData[this.doi]?.title}" (${this.doi})`,
								description: `Visualization for "${this.publicationData[this.doi]?.title}" (${
									this.doi
								}) with a citation depth of ${this.publicationData[this.doi]?.depth} with ${
									this.publicationData[this.doi]?.citations?.length
								} direct citations.`,
								publishedTime: `${this.publicationData[this.doi]?.year}-01-01`,
							};

							dynamicStructuredData(dynamicData);
							dynamicMetaData(dynamicData);
						}

						if (this.publicationData[this.doi]?.citations) {
							this.citationData = {};
							this.citationData = createCitationTree(
								this.publicationData,
								this.doi,
								this.publicationData[this.doi].citations,
							);
						}

						this.updateJSXToDisplay();

						if (nextDOIList?.length > 0 && depth < this.publicationData[this.doi]?.depth) {
							await this.retrievePublicationData(nextDOIList, depth + 1, false);
						}

						this.updateProgressBar(depth + 1);
					} else if (init && depth === 0) {
						window.location.href = `${window.location.origin}/search?query=${doiNameChanger(
							doiProps,
							"db",
						)}`;
					}

					return docs;
				})
				.catch((err) => {
					console.error(err);
				});
		}
	}

	/**
	 * Retrieve publication data
	 * @param {Array} doiList List of DOI IDs to search for
	 * @param {Number} depth Iterative step through the citation tree
	 * @param {Boolean} init If this is the first time being called [true] - [false, default]
	 */
	async retrievePublicationData(doiList, depth = 0, init = false) {
		// Clean up DOI list by removing any empty array elements
		// eslint-disable-next-line no-param-reassign
		doiList = doiList.filter((doi) => doi !== undefined);

		if (doiList?.length > 0) {
			// Split list into smaller chunks
			const chunkSize = 15;

			const chunks = Array(Math.ceil(doiList.length / chunkSize))
				.fill()
				.map((_, index) => {
					const start = index * chunkSize;
					return this.getPublicationData(doiList.slice(start, start + chunkSize), depth, init);
				});

			await Promise.all(chunks);
		} else {
			this.updateJSXToDisplay();
			this.updateProgressBar(0, true);
		}

		if (init) {
			this.updateProgressBar(100000, true);
		}

		return doiList;
	}

	/**
	 * Update the JSX to display
	 */
	updateJSXToDisplay() {
		/** Citation tree data */
		const citationDataUse = {};

		if (this.citationData) {
			citationDataUse.tree = this.citationData;

			citationDataUse.direct = Object.keys(citationDataUse?.tree)?.length;

			citationDataUse.depth = this.publicationData?.[this.doi]?.depth;

			citationDataUse.total = totalCitationsInTree(citationDataUse?.tree);
		}

		// Update data visualization
		this.depthVisualizationJSX = [];

		this.depthVisualizationJSX.push(
			<div key={this.depthID} className="depthVisualization" id={this.depthID}>
				<Depth
					key="depthDepth"
					depth={citationDataUse.depth}
					doi={this.doi}
					domID={this.depthID}
					publicationData={this.publicationData}
					toolbarID={this.toolbarID}
					total={citationDataUse.total}
					tree={citationDataUse.tree}
				/>
			</div>,
		);

		// Update publication's information
		this.publicationsJSX = [];

		this.publicationsJSX.push(
			<Suspense key="publicationDataSuspense" fallback={null}>
				<div key="publicationDataDisplay" id="publicationData">
					<Publications
						key={`publications-${this.doi}`}
						doi={this.doi}
						publicationData={this.publicationData}
						tree={citationDataUse.tree}
					/>
				</div>
			</Suspense>,
		);

		// Update citation's information
		this.citationInfoJSX = [];

		this.citationInfoJSX.push(
			<Suspense key="citationInformationDisplay" fallback={null}>
				<div key="displayCitationInformation" id="citationInformation">
					<CitationInformation
						key={`citation-information-display-${this.doi}`}
						depth={citationDataUse.depth}
						doi={this.doi}
						publicationData={this.publicationData}
						total={citationDataUse.total}
						tree={citationDataUse.tree}
					/>
				</div>
			</Suspense>,
		);

		this.updateDisplay();

		if (!this.infoBarJSX) {
			// Update info bar
			this.infoBarJSX = [];

			this.infoBarJSX.push(
				<div key={this.toolbarID} id={this.toolbarID}>
					<InfoBar
						key="toolbarInfoBar"
						depth={citationDataUse.depth}
						direct={citationDataUse.direct}
						doi={this.doi}
						publicationData={this.publicationData}
						total={citationDataUse.total}
					/>
				</div>,
			);

			this.updateDisplay();

			if (document.getElementById("toolBarDOI")) {
				colourTree(this.publicationData, this.doi);
			} else {
				setTimeout(() => {
					colourTree(this.publicationData, this.doi);
				}, 555);
			}
		}
	}

	/**
	 * Display and update the UI
	 */
	updateDisplay() {
		const { loadingProgress } = this.state;

		/** JSX to be displayed on UI */
		const jsxToDisplay = [];

		jsxToDisplay.push(
			<Suspense key="visualizationSuspenseKey">
				<div key="impactDepth" className="impactDepth" id="impactDepth">
					{this.errorJSX ? (
						this.errorJSX
					) : (
						<>
							<Tooltip arrow describeChild title={`Depth progress bar for ${this.doi}`}>
								<LinearProgress
									key="progressBarViz"
									aria-label={`depth progress bar for ${this.doi}`}
									hidden={loadingProgress <= 0 || loadingProgress >= 100}
									id="progressBar"
									value={loadingProgress}
									variant="determinate"
								/>
							</Tooltip>

							{this.infoBarJSX ? this.infoBarJSX : null}
							{this.depthVisualizationJSX ? (
								<div key="visualizationDisplay" id="Visualize">
									{this.depthVisualizationJSX}
								</div>
							) : null}
							{this.citationInfoJSX ? (
								<div key="citationDisplay" hidden id="Information">
									{this.citationInfoJSX}
								</div>
							) : null}
							{this.publicationsJSX ? (
								<div key="publicationsDisplay" hidden id="Publications">
									{this.publicationsJSX}
								</div>
							) : null}
						</>
					)}
				</div>
			</Suspense>,
		);

		this.setState({
			jsxDisplay: jsxToDisplay,
		});
	}

	/**
	 * Update loading/progress bar
	 * @param {Number} step What depth step has been completed
	 */
	updateProgressBar(step = 0, finish = false) {
		const { loadingProgress } = this.state;

		if (document.getElementById("progressBar")) {
			const max = (this.publicationData?.[this.doi]?.depth || 1) - 1 || 1;
			let progressPercent = finish ? 100 : (step / max) * 100;
			if (progressPercent >= 100) {
				progressPercent = 100;
			} else if (progressPercent <= 0) {
				progressPercent = 0;
			}

			if (progressPercent > loadingProgress) {
				this.setState({
					loadingProgress: progressPercent,
				});
			}

			if (progressPercent <= 0 || progressPercent >= 100 || finish) {
				document.getElementById("progressBar").style.display = "none";
			} else {
				document.getElementById("progressBar").style.display = "block";
			}
		}
	}

	render() {
		const { jsxDisplay } = this.state;

		return jsxDisplay;
	}
}
