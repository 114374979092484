import { Tooltip } from "@mui/material";
import React, { Suspense, lazy } from "react";
import logo from "../../../../images/logo/logo.svg";
import "./footer.scss";

const Button = lazy(() => import("@mui/material/Button"));
const Grid = lazy(() => import("@mui/material/Grid"));
const InstagramIcon = lazy(() => import("@mui/icons-material/Instagram"));
const RedditIcon = lazy(() => import("@mui/icons-material/Reddit"));
const TwitterIcon = lazy(() => import("@mui/icons-material/Twitter"));

export default class Footer extends React.Component {
	constructor() {
		super();

		this.socialMediaInfo = {
			Twitter: {
				url: "https://twitter.com/ImpactDepth",
				text: "Twitter",
				icon: <TwitterIcon />,
			},
			Reddit: {
				url: "https://www.reddit.com/r/ImpactDepth/",
				text: "Reddit",
				icon: <RedditIcon />,
			},
			Instagram: {
				url: "https://www.instagram.com/impactdepth/",
				text: "Instagram",
				icon: <InstagramIcon />,
			},
		};
	}

	/**
	 * Create social media icons
	 * @returns {JSX.Element[]} JSX of social media icons
	 */
	socialMediaIcons() {
		/** Social media icons */
		const socialMediaIcons = [];

		// eslint-disable-next-line no-unused-vars
		for (const [, value] of Object.entries(this.socialMediaInfo)) {
			socialMediaIcons.push(
				<Tooltip key={`footer-button-${value?.text}-social-tooltip`} arrow describeChild title={value?.text}>
					<Button
						key={`footer-button-${value?.text}-social`}
						className="footer-Socials"
						href={value?.url}
						rel="noopener noreferrer"
						startIcon={value?.icon}
						target="_blank"
					/>
				</Tooltip>,
			);
		}

		return socialMediaIcons;
	}

	render() {
		return (
			<Suspense>
				<div className="footer" id="footer" role="contentinfo">
					<Grid alignItems="center" container justifyContent="space-around" spacing={1}>
						<Grid item>
							<img alt="logo" className="footer-Logo" src={logo} />
							<br />
							Copyright © ImpactDepth
						</Grid>
						<Grid item>{this.socialMediaIcons()}</Grid>
					</Grid>
				</div>
			</Suspense>
		);
	}
}
