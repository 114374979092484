import React from "react";
import logo from "../../../../images/logo/logo.svg";
import SearchBar from "../../search-bar/SearchBar";
import "./splash.scss";

export default class Splash extends React.PureComponent {
	render() {
		return (
			<header key="splash-Container" className="splash-Container">
				<div>
					<h1>
						<img
							key="splash-Logo"
							alt="logo"
							className="splash-Logo"
							fetchpriority="high"
							loading="auto"
							src={logo}
						/>
						{`   `}
						<span className="splash-title">IMPACT DEPTH</span>
					</h1>
					<h2 className="splash-Description">Visualize the citation path of your publication</h2>
				</div>

				<SearchBar />
			</header>
		);
	}
}
