import { Stack } from "@mui/material";
import { Suspense, lazy, useEffect, useState } from "react";
import ColourLegend from "./components/ColourLegend";
import { colourTree, downloadSVG, visualizeDepth } from "./depth-functions";
import "./depth.scss";

const IconButton = lazy(() => import("@mui/material/IconButton"));
const PhotoCameraIcon = lazy(() => import("@mui/icons-material/PhotoCamera"));

/** Visualizes the depth of a publication (by DOI) */
export default function Depth({ tree, doi, depth, publicationData }) {
	/** The SVG DOM ID */
	const svgID = "depthTree";

	const [svgWidth] = useState(window.innerWidth);
	const [svgHeight] = useState(window.innerHeight);

	useEffect(() => {
		const updateDepth = async () => {
			await visualizeDepth(tree, doi, depth);
			await colourTree(publicationData, doi);
		};
		updateDepth();
	}, [tree, doi, depth, publicationData]);

	const toolTipStyle = {
		opacity: 0,
		zIndex: 1000,
	};

	return (
		<div id="svgDOMContainer">
			<svg height={svgHeight} id={svgID} width={svgWidth}>
				<g id="svgContainer" transform="translate(5, 10)">
					<g className="links" />
					<g className="nodes" />
				</g>
			</svg>

			<a hidden href={window.location.origin} id="hiddenRedirect" rel="noopener noreferrer" target="_blank">
				Hidden redirect
			</a>

			<div className="tooltip" id="pubTooltip" style={toolTipStyle} />

			<Stack
				alignItems="center"
				className="dataTreeIcons"
				direction="row"
				hidden
				id="dataTreeIcons"
				justifyContent="flex-end"
				spacing={2}
			>
				<ColourLegend />

				<Suspense fallback={null}>
					<IconButton
						aria-label="download SVG as png"
						component="span"
						onClick={() => {
							downloadSVG();
						}}
					>
						<PhotoCameraIcon fontSize="large" />
					</IconButton>
				</Suspense>
			</Stack>
		</div>
	);
}
