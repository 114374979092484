/** Determines if the URL is valid and if so, adjust the canonical URL */
export default function adjustCanonicalURL() {
	// Looks at the URL and changes the rel="canonical" to current URL if valid
	const validHost = "impactdepth.com";

	// Get the current URL
	const currentURL = document?.location?.href;

	// Check if the host is valid and the URL is a content page
	if (document?.location?.host === validHost && currentURL.includes("doi=")) {
		if (document.querySelector('link[rel="canonical"]')) {
			document.querySelector('link[rel="canonical"]').setAttribute("href", currentURL);
		}
	}
}
