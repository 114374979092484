import Example from "../../components/landing/example/Example";
import Features from "../../components/landing/features/Features";
import Footer from "../../components/landing/footer/Footer";
import Splash from "../../components/landing/splash/Splash";
import "./landing.scss";

export default function Landing() {
	return (
		<div key="landing" className="landing">
			<Splash key="landing-splash" />
			<Features key="landing-features" />
			<Example key="landing-example" />
			<Footer key="landing-footer" />
		</div>
	);
}
