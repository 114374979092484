import * as d3 from "d3";
import ReactDOMServer from "react-dom/server";
import { saveSvgAsPng } from "save-svg-as-png";
import depthColourScheme from "./depthColourScheme.json";

/** Width of SVG */
let width = window.innerWidth;
/** Height of the SVG */
let height = window.innerHeight;
/** The citation tree's depth */
let treeDepth = {};
/** The publication citation tree in a hierarchy D3 can read */
let dataHierarchy = {};
/** Total maximum depth of the citation tree */
let treeDataDepth = 0;
/** Publication of interest's DOI */
let doiOfInterest;
/** Default custom/advance options for the depth tree */
const defaultOptions = {
	enableZoom: true,
};
let customOptions = defaultOptions;

/**
 * Reset existing variables
 */
export function resetVariables() {
	width = window.innerWidth;
	height = window.innerHeight;
	treeDepth = {};
	dataHierarchy = {};
	treeDataDepth = 0;
	customOptions = defaultOptions;
}

/**
 * Find the number of citations in each branch/depth of the tree
 * @param {Object} tree Current citation tree/branch
 * @param {Number} depth The current depth/branch
 */
export function treeDepthRow(tree, depth = 1) {
	if (Object.keys(tree)?.length > 0) {
		if (!treeDepth[depth]) {
			treeDepth[depth] = [];
		}

		for (const [key, value] of Object.entries(tree)) {
			if (!treeDepth[depth].includes(key)) {
				treeDepth[depth].push(key);
			}

			if (Object.keys(value)?.length > 0) {
				treeDepthRow(value, depth + 1);
			}
		}
	}
}

/**
 * Converts the citations tree into a readable D3 hierarchy
 * @param {String} doi The DOI ID
 * @param {Object} treeData The citation tree
 * @returns {Object} data - Readable D3 hierarchy
 */
export function convertTreeDataToHierarchy(doi, treeData) {
	/** Hierarchy data object */
	let data = {};

	if (doi) {
		data = {
			name: doi,
		};

		if (treeData) {
			/** All subsequent citation publications */
			const children = [];

			for (const [key, value] of Object.entries(treeData)) {
				children.push(convertTreeDataToHierarchy(key, value));
			}

			if (children?.length > 0) {
				data.children = children;
			}
		}
	}

	return data;
}

/**
 * Display (or hide) the tooltip for each publication node
 * @param {Boolean} enter Make tooltip visible [true, default] or hide it [false]
 * @param {String} doiID DOI ID
 */
function toolTipContent(toolTipNodeRadius = 0, enter = true, doiID = undefined) {
	if (document.getElementById("pubTooltip")) {
		if (!enter) {
			d3.select("#pubTooltip").transition().duration(10).style("opacity", 0);

			// Empty tooltip
			d3.select("#pubTooltip").html("");
		} else if (enter && doiID && window?.publicationData?.[doiID]) {
			/** Publication data for the DOI */
			const pubData = window.publicationData[doiID];
			/** Link to the DOI publication */
			const doiLink = `https://doi.org/${pubData.doi}`;

			const toolTip = (
				<>
					{pubData.title}

					{pubData?.fieldsOfStudy?.[0] ? (
						<>
							<br />
							<br />
						</>
					) : null}

					{pubData?.fieldsOfStudy?.[0] ? `Field of study: ${pubData?.fieldsOfStudy?.[0]}` : null}

					<br />
					<br />

					<a href={doiLink} rel="noopener noreferrer" target="_blank">
						{pubData.doi}
					</a>
				</>
			);

			// Update tooltip
			d3.select("#pubTooltip").html(ReactDOMServer.renderToString(toolTip));

			/** Bounding box elements for the node selected */
			const bbox = document.getElementById(doiID)?.getBoundingClientRect();

			d3.select("#pubTooltip")
				.transition()
				.duration(100)
				.style("left", `${bbox.left + toolTipNodeRadius * 1.5}px`)
				.style("top", `${bbox.top}px`)
				.style("opacity", 0.9);
		}
	}
}

/**
 * Builds and visualizes the D3 tree
 * @param {Object} data A readable D3 hierarchy object
 */
export function buildTreeLayout(data) {
	/** D3 readable data */
	const root = d3.hierarchy(data);

	/** D3 tree layout */
	const treeLayout = d3.tree();

	/** Radius of each node */
	const nodeRadius = 6;

	/** Height of the tree */
	let treeHeight = height;
	/** Width of the tree */
	let treeWidth = width;

	/** Maximum number of nodes per row */
	const maxNodesPerRow = parseInt(width / 45, 10);
	/** Longest citation row divided by maximum number of nodes per row */
	let longestCitationRow = 1;

	for (const [, value] of Object.entries(treeDepth)) {
		if ((value?.length || 0) / maxNodesPerRow > longestCitationRow) {
			longestCitationRow = value.length / maxNodesPerRow;
		}
	}

	// If passes maximum number of nodes per row, change tree size
	if (longestCitationRow > 1) {
		treeWidth *= longestCitationRow;

		// Set max width
		if (treeWidth > 200000) {
			treeWidth = 200000;
		}

		treeHeight = treeWidth * (height / width / Math.sqrt(treeDataDepth));
		if (treeHeight < height) {
			treeHeight = height;
		}
	}

	treeLayout.size([treeWidth, treeHeight * 0.9]);
	treeLayout(root);

	// Zoom was modified from: https://observablehq.com/@d3/zoom
	if (customOptions.enableZoom) {
		d3.select("body").call(
			d3.zoom().on("zoom", (event) => {
				toolTipContent(nodeRadius, false);
				d3.select("svg g").attr("transform", event.transform);
			}),
		);
	}

	// Nodes
	d3.select("svg g.nodes")
		.selectAll("circle.node")
		.data(root.descendants())
		.enter()
		.append("circle")
		.classed("node", true)
		.attr("cx", (d) => d.x)
		.attr("cy", (d) => d.y + 10)
		.attr("r", nodeRadius)
		.attr("id", (d) => d.data.name)

		.on("click", function (_d) {
			if (this?.id && document.getElementById("hiddenRedirect")) {
				document.getElementById("hiddenRedirect").href = `https://doi.org/${this.id}`;
				document.getElementById("hiddenRedirect").click();
			}
		})
		.on("mouseover", (d) => {
			toolTipContent(nodeRadius, true, d.target.id);
		});

	// Links
	d3.select("svg g.links")
		.selectAll("line.link")
		.data(root.links())
		.enter()
		.append("line")
		.classed("link", true)
		.attr("x1", (d) => d.source.x)
		.attr("y1", (d) => d.source.y + 10)
		.attr("x2", (d) => d.target.x)
		.attr("y2", (d) => d.target.y + 10)
		.attr("id", (d) => `${d.target.data.name}-link`)
		.on("mouseover", (d) => {
			/** DOI ID */
			let doiID = d.target.id;
			doiID = doiID.substring(0, doiID.length - 5);

			toolTipContent(nodeRadius, true, doiID);
		});
}

/**
 * Visualize the citation tree
 * @param {Object} dataTree Citation tree
 * @param {String} doi DOI ID
 * @param {Number} depth Maximum tree depth
 * @param {Object} [customSize] Custom size of the SVG
 * @param {Object} [customOptions] Custom options for the depth tree
 */
export async function visualizeDepth(dataTree, doi, depth, customSize = undefined, options = defaultOptions) {
	resetVariables();
	doiOfInterest = doi;
	if (customSize) {
		width = customSize.width;
		height = customSize.height;
	}
	customOptions = options;

	// Remove nodes
	d3.select("svg g.nodes").selectAll("circle.node").remove();

	d3.select("svg g.links").selectAll("line.link").remove();

	treeDataDepth = depth;

	treeDepthRow(dataTree);

	// Create D3 readable hierarchy tree
	dataHierarchy = convertTreeDataToHierarchy(doi, dataTree);

	buildTreeLayout(dataHierarchy);

	// Activate image downloader button
	if (document.getElementById("dataTreeIcons")?.hidden) {
		document.getElementById("dataTreeIcons").removeAttribute("hidden");
	}
}

/**
 * Colour the citation tree based on field of publication/study
 * @param {Object} publicationData All citation publication data
 * @param {String} mainDOI The DOI ID for the publication of interest
 * @param {String} type What type of colouring will be done (default or light mode)
 */
export async function colourTree(publicationData, mainDOI, type = "default") {
	/** Publication data that will be added to the window */
	const publicationDataForWindow = {};

	if (publicationData) {
		for (const [, value] of Object.entries(publicationData)) {
			// Colour nodes
			if (value?.fieldsOfStudy?.[0]) {
				/** Field of study impacted */
				const fieldOfStudy = value.fieldsOfStudy[0];

				if (depthColourScheme[fieldOfStudy.toLowerCase().trim()] && document.getElementById(value.doi)) {
					document.getElementById(value.doi).style.fill =
						depthColourScheme[fieldOfStudy.toLowerCase().trim()];
					if (type === "light") {
						document.getElementById(value.doi).style.stroke = "#000000";
					} else {
						document.getElementById(value.doi).style.stroke = "#a6a6a6";
					}

					if (document.getElementById(`${value.doi}-link`)) {
						document.getElementById(`${value.doi}-link`).style.strokeWidth = 1.5;

						if (type === "light") {
							document.getElementById(`${value.doi}-link`).style.stroke = "#000000";
						} else {
							document.getElementById(`${value.doi}-link`).style.stroke =
								depthColourScheme[fieldOfStudy.toLowerCase().trim()];
						}
					}
				}
			} else if (document.getElementById(`${value.doi}-link`)) {
				document.getElementById(`${value.doi}-link`).style.strokeWidth = 1.5;

				if (type === "light") {
					document.getElementById(value.doi).style.stroke = "#000000";
					document.getElementById(`${value.doi}-link`).style.stroke = "#000000";
				} else {
					document.getElementById(value.doi).style.stroke = "#a6a6a6";
					document.getElementById(`${value.doi}-link`).style.stroke = "#4ac4e2";
				}
			}

			// Update page title
			if (
				value?.doi?.trim().toLowerCase() === mainDOI.trim().toLowerCase() &&
				value?.title &&
				document?.title?.length > 0
			) {
				/** Shortened title of the DOI called */
				let shortenTitle = value.title;
				if (shortenTitle?.length > 64) {
					shortenTitle = `${shortenTitle.substr(0, 64)}...`;
				}

				document.title = `${value.doi}: ${shortenTitle} - Impact Depth`;

				if (
					document.getElementById("toolBarDOI") &&
					!document.getElementById("toolBarDOI").textContent.includes(shortenTitle)
				) {
					document.getElementById("toolBarDOI").textContent += `: ${shortenTitle}`;
				}
			}

			publicationDataForWindow[value.doi] = value;
		}
	}

	window.publicationData = publicationDataForWindow;
}

/**
 * Download SVG as a PNG
 */
export async function downloadSVG() {
	if (document.getElementById("svgContainer")) {
		if (window.publicationData && doiOfInterest) {
			await colourTree(window.publicationData, doiOfInterest, "light");
		}

		await saveSvgAsPng(document.getElementById("svgContainer"), "citationTree.png");

		if (window.publicationData && doiOfInterest) {
			await colourTree(window.publicationData, doiOfInterest);
		}
	}
}
