import {
	AccountTree as AccountTreeIcon,
	ManageSearch as ManageSearchIcon,
	Science as ScienceIcon,
} from "@mui/icons-material";
import React, { Suspense, lazy } from "react";
import "./features.scss";

const Card = lazy(() => import("@mui/material/Card"));
const CardContent = lazy(() => import("@mui/material/CardContent"));
const Grid = lazy(() => import("@mui/material/Grid"));
const Typography = lazy(() => import("@mui/material/Typography"));

export default class Features extends React.Component {
	constructor() {
		super();
		this.features = {
			tree: {
				title: "Citation Tree",
				description:
					"Visualize what peer-reviewed publications have cited your paper and what papers cited that paper",
				icon: <AccountTreeIcon className="features-icon" />,
			},
			impact: {
				title: "Impact",
				description: "See what fields of research and science your publication has impacted",
				icon: <ScienceIcon className="features-icon" />,
			},
			search: {
				title: "Search",
				description:
					"Search for publications based on keywords or ask broad questions to find publications that might related",
				icon: <ManageSearchIcon className="features-icon" />,
			},
		};

		this.state = {
			displayJSX: null,
		};
	}

	componentDidMount() {
		this.displayPrinciples();
	}

	displayPrinciples() {
		const toDisplay = [];

		let size = parseInt(12 / Object.keys(this.features).length, 10) - 1;
		if (size < 1) {
			size = 1;
		}

		for (const [key, value] of Object.entries(this.features)) {
			toDisplay.push(
				<Grid key={key} item md={size} xs={12}>
					<Card className="features-cards">
						<CardContent>
							{value.icon}
							<Typography component="h2" role="heading" variant="h5">
								{value.title}
							</Typography>
							<Typography component="p" role="article" variant="body1">
								{value.description}
							</Typography>
						</CardContent>
					</Card>
				</Grid>,
			);
		}

		this.setState({
			displayJSX: toDisplay,
		});
	}

	render() {
		const { displayJSX } = this.state;

		return (
			<Suspense fallback={null}>
				<div className="features-container" role="navigation">
					<span key="features-Title" className="features-title">
						Features
					</span>
					<Grid container id="features" justifyContent="center" spacing={2}>
						{displayJSX}
					</Grid>
				</div>
			</Suspense>
		);
	}
}
