/* eslint-disable no-unused-vars */
// Import the functions you need from the SDKs you need
import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getPerformance } from "firebase/performance";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	authDomain: `${process.env.REACT_APP_FIREBASE_ID}.firebaseapp.com`,
	databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "G-WXTGLH49ZQ",
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	projectId: process.env.REACT_APP_FIREBASE_ID,
	storageBucket: `${process.env.REACT_APP_FIREBASE_ID}.appspot.com`,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

// Initialize Performance Monitoring and get a reference to the service
const perf = getPerformance(app);

export function logAnalyticsEvent(eventName, eventParams) {
	if (analytics && eventName) {
		logEvent(analytics, eventName, eventParams);
	}
}
