import { AppBar, Grid, Tab, Tabs, Toolbar } from "@mui/material";
import { Suspense, lazy, useCallback, useState } from "react";
import SearchBar from "../search-bar/SearchBar";
import "./info-bar.scss";

const OptionsMenu = lazy(() => import("../optionsMenu/OptionsMenu"));
const MenuIcon = lazy(() => import("@mui/icons-material/Menu"));
const IconButton = lazy(() => import("@mui/material/IconButton"));

/** Display information at the top of the page as a toolbar/app bar */
export default function InfoBar({ doi }) {
	const [displayTab, setDisplayTab] = useState("Visualize");
	const [menuOpen, setMenuOpen] = useState(false);

	const handleOpenMenu = useCallback(() => {
		setMenuOpen((prevMenuOpen) => !prevMenuOpen);
	}, []);

	const handleChange = useCallback(
		(_event, newValue) => {
			if (document.getElementById(newValue) && displayTab !== newValue && document.getElementById(displayTab)) {
				document.getElementById(displayTab).setAttribute("hidden", true);
				document.getElementById(newValue).removeAttribute("hidden");
				setDisplayTab(newValue);

				if (newValue === "Visualize") {
					document.getElementById("html").style.height = "100%";
				} else {
					document.getElementById("html").style.height = "inherit";
				}
			}
		},
		[displayTab],
	);

	return (
		<AppBar position="static">
			<Toolbar
				sx={{
					padding: "1%",
				}}
			>
				<Grid alignItems="center" container direction="row" justifyContent="space-between" spacing={1}>
					<Grid item sm={1} xs={1}>
						<Suspense key="infoBarSuspense-options" fallback={null}>
							<IconButton aria-label="menu" onClick={handleOpenMenu}>
								<MenuIcon />
							</IconButton>
							<OptionsMenu infoBarHandler={handleOpenMenu} openMenu={menuOpen} />
						</Suspense>
					</Grid>

					<Grid item sm={4} xs={11}>
						<a
							className="toolBarDOI"
							href={`https://doi.org/${doi}`}
							id="toolBarDOI"
							rel="noopener noreferrer"
							target="_blank"
						>
							{doi}
						</a>
					</Grid>

					<Grid item sm={3} xs={12}>
						<Tabs
							aria-label="scrollable auto tabs toolbarOptions"
							className="info-bar-tabs"
							indicatorColor="primary"
							onChange={handleChange}
							scrollButtons="auto"
							sx={{
								".MuiTab-root": {
									margin: "auto",
								},
							}}
							textColor="secondary"
							value={displayTab || "Visualize"}
							variant="scrollable"
						>
							<Tab label="Visualize" value="Visualize" />
							<Tab label="Information" value="Information" />
							<Tab label="Publications" value="Publications" />
						</Tabs>
					</Grid>

					<Grid item sm={4} xs={12}>
						<SearchBar maxWidth={100} />
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
}
