import { Snackbar } from "@mui/material";
import { Suspense, lazy, useEffect, useState } from "react";
import "./App.scss";
import { doiNameChanger } from "./helpers/doi";
import { dynamicMetaData, dynamicStructuredData } from "./helpers/helper";
import adjustCanonicalURL from "./helpers/meta";
import LandingPage from "./pages/landing/Landing";
import Visualization from "./pages/visualization/Visualization";

const Cookies = lazy(() => import("./components/policy/Cookies"));
const Privacy = lazy(() => import("./components/policy/Privacy"));
const SearchPage = lazy(() => import("./pages/search/SearchPage"));
const Button = lazy(() => import("@mui/material/Button"));
const Dialog = lazy(() => import("@mui/material/Dialog"));
const DialogContent = lazy(() => import("@mui/material/DialogContent"));
const DialogTitle = lazy(() => import("@mui/material/DialogTitle"));

/** Main app component */
export default function App() {
	const [displayJSX, setDisplayJSX] = useState(null);
	const [displayCnP, setDisplayCnP] = useState(false);
	const [displayCnPDialog, setDisplayCnPDialog] = useState(false);
	/** What type of page will be rendered */
	const [pageType, setPageType] = useState("landing");
	const [doi, setDoi] = useState(undefined);

	/**
	 * Determines page type
	 */
	function determinePageType() {
		adjustCanonicalURL();
		/** URL pathname */
		const pathname = document?.location?.pathname;
		let searchString = document?.location?.search;

		if (pathname?.toLowerCase() === "/viz" && searchString?.trim().toLowerCase().includes("doi")) {
			if (searchString[0] === "?") {
				searchString = searchString.substring(1, searchString.length);
			}
			const searchParams = searchString.split("=");

			if (searchParams?.[0] === "doi" && searchParams?.[1]) {
				setDoi(doiNameChanger(searchParams[1], "pub"));

				if (document?.title?.length > 0) {
					document.title = `${doi} - Impact Depth`;
				}
			}

			setPageType("doi");
		} else if (
			pathname?.trim().toLowerCase().includes("search") &&
			searchString?.trim().toLowerCase().includes("query")
		) {
			if (searchString[0] === "?") {
				searchString = searchString.substring(1, searchString.length);
			}
			const searchParams = searchString.split("=");

			if (searchParams?.[0] === "query" && searchParams?.[1]) {
				setDoi(doiNameChanger(decodeURIComponent(searchParams[1]), "pub"));
				if (document?.title?.length > 0) {
					document.title = `${doi} - Impact Depth`;
				}
			}
			setPageType("search");
		} else {
			setPageType("landing");
		}
	}

	/**
	 * Display the page type
	 */
	async function displayPageType() {
		/** JSX to display */
		const jsxToDisplay = [];

		if (pageType === "doi") {
			jsxToDisplay.push(<Visualization key="display-visualization" doi={doi} />);
		} else if (pageType === "search") {
			/** New dynamic data for meta tags and structured data */
			const dynamicData = {
				title: `"${doi}" search - Impact Depth`,
				description: `Search results for "${doi}"`,
			};

			dynamicMetaData(dynamicData);
			dynamicStructuredData(dynamicData);

			jsxToDisplay.push(
				<Suspense key="search-page" fallback={null}>
					<SearchPage key="display-search-page" query={doi} />
				</Suspense>,
			);
		} else {
			// Default landing page
			dynamicStructuredData();
			jsxToDisplay.push(<LandingPage key="display-landing-page" />);
		}

		setDisplayJSX(jsxToDisplay);
	}

	/**
	 * Determines if a cookies and policy should be displayed or not
	 */
	async function determineCookiesAndPolicy() {
		if (window.localStorage?.getItem("@ImpactDepth/version") !== process.env.REACT_APP_VERSION) {
			setDisplayCnP(true);

			window.localStorage.setItem("@ImpactDepth/version", process.env.REACT_APP_VERSION);
		}
	}

	/**
	 * Handle click on cookie and policy snackbar for closing
	 * @param {*} event
	 * @param {*} reason
	 * @returns
	 */
	function handleClickCnPSnackbar(_event, reason) {
		if (reason === "clickaway") {
			return;
		}

		setDisplayCnP(false);
	}

	/**
	 * Handle click cookie and policy dialog
	 */
	function handleClickCnPDialog() {
		setDisplayCnP(!displayCnP);
		setDisplayCnPDialog(!displayCnPDialog);
	}

	/** Resize the SVG when the page resizes */
	function resizeSVG() {
		if (document.getElementById("depthTree")) {
			document.getElementById("depthTree").setAttribute("height", window.innerHeight);
			document.getElementById("depthTree").setAttribute("width", window.innerWidth);
		}
	}

	useEffect(() => {
		displayPageType();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pageType, doi]);

	useEffect(() => {
		determinePageType();
		determineCookiesAndPolicy();

		window.addEventListener("resize", resizeSVG, { passive: true });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<main key="app" className="App" id="app" role="main">
			{displayJSX}

			<Snackbar
				action={
					<>
						<Button
							key="cnp-policy"
							color="secondary"
							onClick={() => handleClickCnPDialog()}
							variant="outlined"
						>
							See our cookies and privacy policy
						</Button>
						<Button
							key="cnp-close"
							color="secondary"
							onClick={() => handleClickCnPSnackbar()}
							variant="outlined"
						>
							CLOSE
						</Button>
					</>
				}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
				message="We use cookies to improve your experience!"
				onClose={() => handleClickCnPSnackbar()}
				open={displayCnP}
			/>

			<Suspense fallback={null}>
				<Dialog onClose={() => handleClickCnPDialog()} open={displayCnPDialog}>
					<DialogTitle id="cookies-and-privacy-dialog">Cookies and Privacy Policy</DialogTitle>

					<DialogContent>
						<Suspense key="CnPPolicy" fallback={null}>
							<Cookies />
							<br />
							<hr />
							<br />
							<Privacy />
						</Suspense>
					</DialogContent>
				</Dialog>
			</Suspense>
		</main>
	);
}
