import * as Realm from "realm-web";

/**
 * Logs in to MongoDB Realm anonymously and returns a MongoDB database instance.
 *
 * The MongoDB Realm app ID, client, and database name are configured using the following environment variables:
 * - REACT_APP_MONGODB_ID
 * - REACT_APP_MONGODB_CLIENT
 * - REACT_APP_MONGODB_DB
 *
 * @returns {Promise<Realm.Services.MongoDB.MongoDBDatabase>} The MongoDB database instance.
 * @throws {Error} If any of the required environment variables are not set.
 */
export default async function loginAnonymous() {
	if (
		!process.env.REACT_APP_MONGODB_ID ||
		!process.env.REACT_APP_MONGODB_CLIENT ||
		!process.env.REACT_APP_MONGODB_DB
	) {
		throw new Error("Missing MongoDB Realm configuration");
	}

	const app = new Realm.App({ id: process.env.REACT_APP_MONGODB_ID });
	const credentials = Realm.Credentials.anonymous();

	try {
		await app.logIn(credentials);

		const mongodb = app.currentUser.mongoClient(process.env.REACT_APP_MONGODB_CLIENT);
		return mongodb.db(process.env.REACT_APP_MONGODB_DB);
	} catch (err) {
		console.error("Failed to log in", err);
		throw err;
	}
}
