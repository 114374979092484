import { isEmpty } from "lodash";

/**
 * Change DOI converted from database format or publication format
 * @param {String} doi DOI ID
 * @param {String} type "pub" for internal publication sorting (default), "db" for MongoDB readable
 * @returns {String} DOI
 */
export function doiNameChanger(doi, type = "pub") {
	const typeLower = type.trim().toLowerCase();
	if (typeLower === "pub") {
		return decodeURIComponent(doi);
	}

	if (typeLower === "db") {
		return encodeURIComponent(doi);
	}

	return doi;
}

/**
 * Iterative steps through a DOI citation data tree
 * @param {Object} stepData The step in the DOI citation tree
 * @param {Object} newData The new data being modified
 * @param {String} type "pub" for internal publication sorting (default), "db" for MongoDB readable
 */
export function doiNameTreeChangerIterative(stepData, newData, type = "pub") {
	for (const [key, value] of Object.entries(stepData)) {
		newData[doiNameChanger(key, type)] = {};

		if (!isEmpty(value)) {
			doiNameTreeChangerIterative(value, newData[doiNameChanger(key, type)], type);
		}
	}
}

/** Temporary tree to store the DOI name changer */
let tempTree = {};
/**
 * Change all the DOI IDs in a data tree
 * @param {Object} originalData The original DOI citation data tree
 * @param {String} type "pub" for internal publication sorting (default), "db" for MongoDB readable
 * @returns {Object} The modified DOI citation data tree in type desired
 */
export function doiNameTreeChanger(originalData, type = "pub") {
	tempTree = {};

	for (const [key, value] of Object.entries(originalData)) {
		tempTree[doiNameChanger(key, type)] = {};

		if (!isEmpty(value)) {
			doiNameTreeChangerIterative(value, tempTree[doiNameChanger(key, type)], type);
		}
	}

	return tempTree;
}

/**
 * Calculate tree depth
 * @param {Object} tree Citation tree
 * @param {Number} itDepthStep Iteration step (depth of tree)
 * @returns {Number} Citation tree depth
 */
export function treeDepth(tree, itDepthStep = 1) {
	if (isEmpty(tree)) {
		return 0;
	}

	/** List of possible citation depth steps */
	const maxDepthOptions = [itDepthStep];

	for (const [, value] of Object.entries(tree)) {
		if (!isEmpty(value)) {
			maxDepthOptions.push(treeDepth(value, itDepthStep + 1));
		} else {
			maxDepthOptions.push(0);
		}
	}

	return Math.max(...maxDepthOptions);
}

/**
 * Citations within each level of the tree
 * @param {Object} treeBranch The tree branch
 * @param {Array} citationList The array the citations will be added to
 */
function citationsWithinTree(treeBranch, citationList = []) {
	for (const [key, value] of Object.entries(treeBranch)) {
		if (!citationList.includes(key)) {
			citationList.push(key);

			if (!isEmpty(value)) {
				citationsWithinTree(value, citationList);
			}
		}
	}
}

/**
 * Total number of citations in the citation tree
 * @param {Object} tree The citation tree data
 * @param {Boolean} returnCitations Whether the tree should return total count [false, default] or the citations itself [true]
 * @returns {Number} The total citations in a tree (if error, it will be 0)
 */
export function totalCitationsInTree(tree, returnCitations = false) {
	/** All citations in the citation tree */
	let citationsInTree = [];

	for (const [key, value] of Object.entries(tree)) {
		citationsInTree.push(key);

		if (Object.keys(value).length > 0) {
			citationsWithinTree(value, citationsInTree);
		}
	}

	citationsInTree = [...new Set(citationsInTree)];

	if (!returnCitations) {
		if (citationsInTree) {
			return citationsInTree.length;
		}
		return 0;
	}
	return citationsInTree;
}

/**
 * Reorganize publication data from list to organized object
 * @param {Array} pubList List of publications
 * @returns {Object} Organized object of the publications
 */
export function reorganizePubData(pubList) {
	/** Publication data */
	const pubData = {};

	for (const i in pubList) {
		if (pubList[i]?.doi) {
			pubData[pubList[i].doi] = pubList[i];
		}
	}

	return pubData;
}

/**
 * Modify and/or create a citation tree
 * @param {String} mainDOI The main DOI ID
 * @param {String} parentDOI The DOI ID of the parent in the tree
 * @param {Array} citations List of citations DOIs
 * @param {Object} data Parent citation tree
 */
export function modifyCitationTree(publicationData, mainDOI, parentDOI, citations, data, noRepeat) {
	for (const i in citations) {
		if (citations[i]) {
			/** Current citation DOI */
			const citationDOI = citations[i];

			if (!noRepeat.includes(citationDOI)) {
				let modifyTree = false;

				if (parentDOI) {
					if (publicationData?.[parentDOI]?.year <= publicationData?.[citationDOI]?.year) {
						modifyTree = true;
					}
				}

				if (modifyTree) {
					noRepeat.push(citationDOI);

					data[citationDOI] = {};

					if (publicationData?.[citationDOI]?.citations?.length > 0) {
						modifyCitationTree(
							publicationData,
							mainDOI,
							citationDOI,
							publicationData[citationDOI].citations,
							data[citationDOI],
							noRepeat,
						);
					}
				}
			}
		}
	}
}

/**
 * Create citation tree
 * @param {Object} publicationData Publication data
 * @param {String} mainDOI DOI from publication of interest
 * @param {Array} citations List of direct citations
 * @returns {Object} Citation tree
 */
export function createCitationTree(publicationData, mainDOI, citations) {
	/** DOIs not to repeat */
	const noRepeatCitations = [];
	/** Citation tree */
	const citationTree = {};

	modifyCitationTree(publicationData, mainDOI, mainDOI, citations, citationTree, noRepeatCitations);

	return citationTree;
}
